_self.cookie = {
    // возвращает cookie с именем name, если есть, если нет, то undefined
    get: function(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    
    // устанавливает cookie с именем name и значением value
    // options - объект с свойствами cookie (expires, path, domain, secure)
    set: function(name, value, options) {
        options = options || {};
    
        let expires = options.expires;
    
        if (typeof expires == "number" && expires) {
            let d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }
    
        value = encodeURIComponent(value);
    
        let updatedCookie = name + "=" + value;
    
        for (let propName in options) {
            updatedCookie += "; " + propName;
            let propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }
    
        document.cookie = updatedCookie;
    },
    
    // удаляет cookie с именем name
    del: function(name) {
        _self.cookie.set(name, "", {
            expires: -1
        })
    }
};
