/**
 * Инициализация плагина `jquery.inputmask`
 * @see  {@link http://robinherbots.github.io/Inputmask/}
 * @see  {@link http://andr-04.github.io/inputmask-multi/ru.html} - расширение для динамической подстановки маски на основании первых введенных цифер
 *
 * @sourcecode wHTML:inputMask
 * @memberof   wHTML
 * @param      {Element}     [$context] - родительский элемен
 * @return     {undefined}
 */
wHTML.prototype.inputMask = function( $context ) {
	var $maskElement = $( '.js-inputmask', $context );

	if ( !$maskElement.length ) {
		return;
	}

	var listCountries = $.masksSort($.masksLoad($maskElement.data('codes')), ['#'], /[0-9]|#/, "mask");
	var maskOpts = {
		inputmask: {
			definitions: {
				'#': {
					validator: "[0-9]",
					cardinality: 1
				}
			},
			showMaskOnHover: false,
			autoUnmask: true,
			clearMaskOnLostFocus: true
		},
		match: /[0-9]/,
		replace: '#',
		listKey: "mask"
	};

	var maskChangeWorld = function(maskObj, determined, $el) {
		var $wrapper = $el.closest('.control');
		var $hintContainer = $wrapper.find('.mask-country-for');

		if (determined) {
			var lang = document.documentElement.lang;
			var hint = maskObj['name_'+lang];

			if (maskObj['desc_'+lang] && maskObj['desc_'+lang] !== "") {
				hint += " (" + maskObj['desc_'+lang] + ")";
			}
			$wrapper.addClass('mask-success');
			$hintContainer.html(hint);
		} else {
			$wrapper.removeClass('mask-success');
			$hintContainer.html('');
		}
	}

	$maskElement.each(function(index, el) {
		var $el = $(el);
		var maskInited = $el.data('mask-inited');
		if (maskInited) {
			return true;
		}

		$el.data('mask-inited', true);
		$el.inputmasks($.extend(true, {}, maskOpts, {
			list: listCountries,
			onMaskChange: function(maskObj, determined) {
				maskChangeWorld(maskObj, determined, $el);
			}
		}));
	});
}


// wHTML.prototype.inputMask = function( $context ) {
//
// 	var $maskElement = $( '.js-inputmask', $context );
// 	if ( !$maskElement.length ) {
// 		return;
// 	}
//
// 	$maskElement.each(function(index, el) {
// 		var $el = $(el);
// 		var maskInited = $el.data('mask-inited');
// 		if (maskInited) {
// 			return true;
// 		}
//
// 		var mask = $el.data('mask') || '+38(099)9999999';
// 		// фикс для андроидов, на которых "пригает каретка"
// 		if (Modernizr.android5) {
// 			mask = $el.data('android-fix-mask') || '+380999999999';
// 		}
//
// 		$el.data('mask-inited', true);
// 		$el.inputmask({
// 			mask: mask,
// 			clearMaskOnLostFocus: true
// 		});
// 	});
// }
