/**
 * Инициализация `iframe` метода плагина `magnific-popup`
 * @see  {@link http://dimsemenov.com/plugins/magnific-popup/documentation.html#inline-type}
 * 
 * @sourcecode wHTML:mfpIframe
 * @memberof   wHTML
 *
 * @param selector - по-умолчанию '.js-mfp-iframe'
 */
wHTML.prototype.mfpIframe = function(selector) {
    selector = selector || '.js-mfp-iframe';

    $(selector).each(function (index, el) {
        let $el = $(el);
        let customConfig = $el.data('mfpCustomConfig') || {};

        let currentConfig = $.extend(true, customConfig, {
            type: 'iframe',
            closeBtnInside: true,
            removalDelay: 300,
            mainClass: 'zoom-in'
        });

        $el.magnificPopup(currentConfig);
    });
}